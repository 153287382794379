import CustomerTab from '../../modal-content-buttons/customer-tab'
import Table from '../../modal-content-buttons/table'
import { Button, GiveUpButton, ImageWrapper, Info, Wrapper } from '../styled'
import { Display } from './styled'
import { Send } from '../../../../../assets/images'
import { useState } from 'react'

interface CallCustomerProps {
	name: string
	customerTab: string
	table?: string
	onClose: () => void
	onConfirm: (customerTab: string | undefined, table: string | undefined) => void
	automatic?: boolean
}

const CallCustomer = ({
	name,
	customerTab: customerTabProp,
	table: tableProp,
	onClose,
	onConfirm,
	automatic,
}: CallCustomerProps) => {
	const color = 'var(--color-success)'
	const [table, setTable] = useState<string | undefined>(tableProp)
	const [customerTab, setCustomerTab] = useState<string | undefined>(customerTabProp)

	return (
		<Wrapper>
			<ImageWrapper>
				<Send />
			</ImageWrapper>
			<Info>Deseja chamar {name}?</Info>
			<Display>
				<CustomerTab
					automatic={automatic}
					customerTab={customerTab}
					onChangeCustomertab={customerTab => setCustomerTab(customerTab)}
				/>
				<Table table={table} onChangeTable={table => setTable(table)} />
			</Display>
			<Button color={color} onClick={() => onConfirm(customerTab, table)}>
				Confirmar
			</Button>
			<GiveUpButton color={color} onClick={onClose}>
				Desistir
			</GiveUpButton>
		</Wrapper>
	)
}

export default CallCustomer
